import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import Stack from 'components/commons/Stack'
import DailyBonus from 'components/commons/DailyBonus'

import SessionsSlider from 'containers/domains/Home/SessionsSlider'
import PrivateSessionSlider from 'containers/domains/Home/PrivateSessionSlider/v2'
import ScreenLayout from 'containers/commons/Layout/v2'
import ContentSlider from 'containers/domains/Home/ContentSlider/v2'
import BannerSkeleton from 'containers/domains/Home/Banner/Skeleton'
import CreatorsSliderSkeleton from 'containers/domains/Home/CreatorsSlider/Skeleton'
import ExploreCommunitiesBanner from 'containers/domains/Home/ExploreCommunitiesBanner'
import ReferralCodeExistDrawer from 'containers/domains/User/ReferralCodeExistDrawer'
import ReferralCodeDrawer from 'containers/domains/User/ReferralCodeDrawer'

import trackEvent from 'src/trackers'
import { setUserDevice } from 'helpers/user-device'
import { setWithExpiry } from 'helpers/local-storage'
import { addDays } from 'helpers/date-time'
import { getIdToken } from 'helpers/auth'
import { isGuestMode } from 'helpers/cookie'
import { useFeatureFlags } from 'helpers/feature-flag'

import {
  useMessageStore,
  useReferralStore,
} from 'stores/domains/User'
import { useNotificationStore } from 'stores/commons'
import { useThematicCampaignStore } from 'stores/domains/Campaign'
import { useBrowseStore } from 'stores/domains/Browse'
import { useCampaignSection } from 'hooks/domains/Campaign'
import { styled, useMediaQuery, useTheme } from '@mui/material'
import ExploreSection from 'containers/domains/Home/ExploreSection'

const Container = styled(Stack)(({ theme }) => ({
  position: 'relative',
  top: '-24px',
  background: theme.palette.background.primary,
  borderRadius: '24px 24px 0px 0px',
  zIndex: 2,
  marginTop: `0px !important`,
  width: '100%',
  gap: 32,
  paddingTop: 16,
}))

const EventRecommendationSlider = dynamic(
  () => import('containers/domains/Home/EventRecommendation'),
  {
    ssr: false,
  },
)

const CommunityRecommendationSlider = dynamic(
  () => import('containers/domains/Home/CommunityRecommendation'),
  {
    ssr: false,
  },
)

const CreatorsSlider = dynamic(
  () => import('containers/domains/Home/CreatorsSlider'),
  { loading: () => <CreatorsSliderSkeleton />, ssr: false },
)

const HomeBanner = dynamic(
  () => import('containers/domains/Home/Banner'),
  { loading: () => <BannerSkeleton />, ssr: false },
)

const SecondaryBanner = dynamic(
  () => import('containers/domains/Home/Banner/Secondary'),
  { loading: () => <BannerSkeleton secondary />, ssr: false },
)

const ThematicCampaign = dynamic(
  () =>
    import('containers/domains/Campaign/ThematicCampaignContainer'),
  { ssr: false },
)

const MyCommunityContent = dynamic(
  () => import('containers/domains/Home/MyCommunityContent'),
  { loading: () => <CreatorsSliderSkeleton />, ssr: false },
)

const CommunityStepperOnboarding = dynamic(
  () =>
    import('containers/domains/Community/Mission/StepperOnboarding'),
  {
    ssr: false,
  },
)

export default function HomeGuest() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { pathname, query, isReady } = useRouter()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const idToken = getIdToken()
  const trackerData = { pathname, query }
  const { t } = useTranslation()

  const flagDailyBonus = useFeatureFlags('daily-bonus')
  const isCommunityBannerActive = useFeatureFlags(
    'home-banner-community',
  )
  const { setCurrentStatusPage } = useBrowseStore((state) => ({
    setCurrentStatusPage: state.setCurrentStatusPage,
  }))

  const { data: campaignSection, refetch: refetchCampaignSection } =
    useCampaignSection()
  const { getChats } = useMessageStore((state) => ({
    getChats: state.getChats,
  }))
  const { getListNotification } = useNotificationStore((state) => ({
    getListNotification: state.getListNotification,
  }))
  const { showReferralExistDrawer } = useReferralStore((state) => ({
    referralCodeInfo: state.referralCodeInfo,
    clearReferralCodeInfo: state.clearReferralCodeInfo,
    showReferralExistDrawer: state.showReferralExistDrawer,
  }))

  const { setCampaignSectionList } = useThematicCampaignStore(
    (state) => ({
      setCampaignSectionList: state.setCampaignSectionList,
    }),
  )

  useEffect(() => {
    if (campaignSection) {
      setCampaignSectionList(campaignSection)
    }
  }, [campaignSection])

  useEffect(() => {
    if (!isGuestMode()) {
      setIsLoggedIn(true)
      getChats()
      getListNotification({
        page: 1,
        limit: 5,
        type: 1,
        isReset: true,
      })
      getListNotification({
        page: 1,
        limit: 5,
        type: 2,
        isReset: true,
      })
    } else {
      setIsLoggedIn(false)
    }
  }, [idToken])

  useEffect(() => {
    if (isReady) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener(
          'message',
          (event) => {
            // eslint-disable-next-line no-console
            console.log('event for the service worker', event)
          },
        )
      }
      if (query?.['~click_id']) {
        setWithExpiry(
          'accesstrade',
          String(query?.['~click_id']) || '',
          addDays(30),
        )
      }
      trackEvent.home('visit_home', trackerData, {
        user_type: getIdToken() ? 'supporter' : 'guest',
      })
      setUserDevice()
      setCurrentStatusPage('all')
      refetchCampaignSection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  return (
    <ScreenLayout
      sxMainContainer={{
        padding: {
          md: '0px ',
        },
      }}
      renderBackgroundLogo
      renderAppBanner
      renderBottomBar
      isHome
    >
      <Stack
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        spacing={2}
      >
        <HomeBanner pathName={pathname} />
        <Container>
          <ThematicCampaign index={0} />
          {/* Live Now Section */}
          <SessionsSlider
            title={t('liveNow') + ` 🎥`}
            path="v1/live"
            groupName="session_live_now"
            pathName={pathname}
            pathSeeAll={`/browse?status=session&source=${pathname}&source_action=browse_live_session`}
            sxContainer={{
              padding: isDesktop
                ? '0px 128px !important'
                : '0px 16px !important',
            }}
          />
          {/* Community Stepper Section */}
          <CommunityStepperOnboarding />
          {/* Event Recommendation Section */}
          <EventRecommendationSlider />
          {isLoggedIn && <MyCommunityContent />}
          <ThematicCampaign index={1} />
          <ThematicCampaign index={2} />
          {isCommunityBannerActive && <ExploreCommunitiesBanner />}
          <SecondaryBanner pathName={pathname} />
          {/* Community Recommendation Section */}
          <CommunityRecommendationSlider />
          <CreatorsSlider pathName={pathname} />
          <ThematicCampaign index={3} />
          <ThematicCampaign index={4} />
          {/* Digital Content Recommendation Section */}
          <ContentSlider
            title={t('digitalContentRecommendation') + ` ⚡️`}
            sortBy="best_seller"
            endpointUrl={
              !isGuestMode()
                ? '/recommendation/v1/content'
                : '/recommendation/guest/v1/content'
            }
            token={isGuestMode() ? null : getIdToken()}
            sxContainer={{
              padding: isDesktop
                ? '0px 128px !important'
                : '0px 16px !important',
            }}
          />
          {/* Upcoming Session Section */}
          <SessionsSlider
            title={t('upcomingSessions') + ` 🎥`}
            path="/"
            groupName="session_upcoming"
            pathName={pathname}
            pathSeeAll={`/browse?status=session&source=${pathname}&source_action=browse_upcoming_session`}
            showViewAll
            numOfCol={4}
            sxContainer={{
              padding: isDesktop
                ? '0px 128px !important'
                : '0px 16px !important',
            }}
          />
          {/* Session 1 on 1 Session Section */}
          <PrivateSessionSlider />
          <ExploreSection />
        </Container>
        <ReferralCodeDrawer />
        {showReferralExistDrawer && <ReferralCodeExistDrawer />}
      </Stack>
      {flagDailyBonus && <DailyBonus />}
    </ScreenLayout>
  )
}
